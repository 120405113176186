<script setup lang="ts">
import { useTheme } from 'vuetify'
import { isDark } from '~/composables/utils'

onMounted(() => {
  useTheme().global.name.value = isDark() ? 'dark' : 'light'
})
</script>

<template>
  <v-app>
    <!-- TODO   -->
    <!--    <MainHeaderGuest /> -->

    <v-main class="pt-16">
      <v-container fluid class="page-wrapper">
        <slot />
      </v-container>
    </v-main>
  </v-app>
</template>

<style scoped lang="scss">
.page-wrapper {
  max-width: 1300px;
}
</style>
